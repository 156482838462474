import mat4 from 'gl-mat4';

import { TAU, map } from './customMath';



// Returns a vec3-like array, but the array is recycled for each call. This is perfect when a vec3 is needed
// for a single syncronous purpose, without ownership of the object. It avoids any object creation / GC overhead.
// Beware that strange bugs can manifest if a reference is kept to the returned object.
const v3Cache = [0, 0, 0];
export const v3 = (x, y, z) => {
	v3Cache[0] = x;
	v3Cache[1] = y;
	v3Cache[2] = z;
	return v3Cache;
};


// Helper to make assertions on expressions that must be true for the program to work.
export function invariant(condition, message) {
	if (!condition) {
		throw new Error('[[ glow.gl ]] ' + message);
	}
}


/**
 * Maps a number from one value to another, using a custom scale with arbitrary mappings.
 * This is useful when it's not simple to fit a mathematical function to a dataset.
 * The scale is represented as an array of arrays, with each sub-array containing two values: an input and output.
 * The scale must be pre-sorted by input values for the algorithm to work.
 * When a position is given that matches an input on the scale, the predefined output is returned.
 * When a given position lies between two inputs on the scale, the returned value is interpolated from the nearest output values.
 * When a given position lies outside the scale, the nearest output is used, essentially extending the end values in both directions.
 *
 * @param  {Number[][]} scale     A custom scale mapping inputs to arbitrary outputs.
 * @param  {Number}     position  The input position to map on the scale.
 *
 * @return {Number}
 */
export const mapFromScale = (scale, position) => {
	if (position <= scale[0][0]) {
		return scale[0][1];
	}

	const scaleLength = scale.length;
	// Currently we only work with small data sets (< 10 items) in non perf-sensitive code, so a simple search is sufficient.
	for (let i=1; i<scaleLength; i++) {
		const upper = scale[i];
		if (position <= upper[0]) {
			const lower = scale[i-1];
			return map(position, lower[0], upper[0], lower[1], upper[1]);
		}
	}

	return scale[scaleLength-1][1];
};



// Variables for `createBurst`
// ----------------------------
// Golden angle in radians, used to generate Fibonacci spiral.
const phi = Math.PI * (3 - Math.sqrt(5));
// Arrays that are reused for each burst generation to avoid GC.
const burstRotationMatrix = [];
const burstRotationAxis = [];
const particleTempPoint = [0, 0, 0, 1];

/**
 * Distributes `count` points on unit sphere, then randomly rotates the sphere for a unique appearance.
 * The returned vectors represent the normalized travel direction of each star. They can be multiplied
 * by the star's initial speed to get the star's initial velocity vector.
 *
 * @param  {Number}   count     The number of stars/particles.
 * @param  {Function} factory   Called once per star/particle generated. Passed four arguments:
 *                                  `i`: The current particle index
 *                                  `x`: The normalized travel direction on X axis (-1 to +1)
 *                                  `y`: The normalized travel direction on Y axis (-1 to +1)
 *                                  `z`: The normalized travel direction on Z axis (-1 to +1)
 *
 * @return {undefined}          Returns nothing; it's up to `factory` to use the given data.
 */
export const createBurst = (count, factory) => {
	const randomAngle = Math.random() * TAU;
	burstRotationAxis[0] = Math.random();
	burstRotationAxis[1] = Math.random();
	burstRotationAxis[2] = Math.random();
	mat4.fromRotation(burstRotationMatrix, randomAngle, burstRotationAxis);

	const countMinusOne = count - 1;
	for (let i=0; i<count; i++) {
		// Fibonacci sphere algorithm from https://stackoverflow.com/a/26127012/
		const y = 1 - 2*(i / countMinusOne);  // y goes from 1 to -1
		const radius = Math.sqrt(1 - y*y);  // radius at y
		const theta = phi * i;  // golden angle increment
		const x = Math.cos(theta) * radius;
		const z = Math.sin(theta) * radius;

		particleTempPoint[0] = x;
		particleTempPoint[1] = y;
		particleTempPoint[2] = z;
		mat4.multiply(particleTempPoint, burstRotationMatrix, particleTempPoint);

		factory(
			i,
			particleTempPoint[0],
			particleTempPoint[1],
			particleTempPoint[2]
		);
	}
};
