import commonReglConfig from './commonReglConfig';
import { GRAVITY, SPARK_POINT_SIZE_MAX, SPARK_POINT_ALPHA_MIN } from './constants';
import { random, interpolate } from './customMath';



// Terminal velocity (m/s)
const COMET_VT = '20.0';
const SPARK_VT = '3.2';
const HEADING_POINT_SIZE = '1000.0';
const BASE_SPARK_POINT_SIZE = 5;



export function makeCometProgram(glow, duration, sparkHz, sparkBurnTime) {
	const { regl, camera, starTexture } = glow;

	const sparkCount = glow._getQualityAdjustedSparkCount(sparkHz * duration);
	const sparkBurnVariation = 0.5;
	const sparkSpeed = 3.2;

	const burnVarianceLow = 1 - sparkBurnVariation;

	const headingData = new Float32Array(2);
	headingData[1] = 1;

	const sparkVelocities = new Float32Array(3 * sparkCount);
	const sparkData = new Float32Array(3 * sparkCount);

	for (let i=0; i<sparkCount; i++) {
		const velocityOffset = i * 3;
		const dataOffset = i * 3;

		const p = i / sparkCount;
		const strength = (1 - p ** 8) * 0.5 + 0.5; // 1.0 to 0.5
		const angle = Math.random() * Math.PI * 2;
		const radius = Math.sqrt(Math.random()) * sparkSpeed * strength;
		// velocity
		sparkVelocities[velocityOffset] = Math.cos(angle) * radius;
		sparkVelocities[velocityOffset+1] = Math.sin(angle) * radius;
		sparkVelocities[velocityOffset+2] = random(-sparkSpeed, sparkSpeed) * strength;
		// spawn time (relative to comet heading's life)
		// Use exponential curves to create more sparks early in comet's life, and reduce spark output as it dies.
		const spawnTimeRelative = interpolate(p**1.6, p**6, p**2);
		// Absolute start/end times
		const startTime = spawnTimeRelative * duration;
		const endTime = startTime + random(burnVarianceLow, 1) * sparkBurnTime;
		// spark start time (absolute)
		sparkData[dataOffset] = startTime;
		// spark end time (absolute)
		sparkData[dataOffset+1] = endTime;
		// heading velocity factor - fraction of comet heading velocity that sparks inherit
		sparkData[dataOffset+2] = interpolate(0.15, 0.75, spawnTimeRelative);
	}

	const drawHeading = regl({
		...commonReglConfig,
		vert: `
			precision highp float;

			attribute float a_data;

			uniform vec3 u_origin;
			uniform vec2 u_velocity;
			uniform float u_time;
			uniform float u_pxPerDegFov;
			uniform mat4 u_projectionViewMatrix;

			varying float v_life;

			// Gravitational acceleration (m/s^2)
			const float g = ${GRAVITY};
			// terminal velocity (m/s)
			const float cometVt = ${COMET_VT};

			float map(float value, float min1, float max1, float min2, float max2) {
				return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
			}

			vec2 projectileMotion2d(vec2 v0, float vt, float t) {
				float multiplier = 1.0 - exp(-g * t / vt);
				return vec2(
					vt * v0.x / g * multiplier,
					vt / g * (v0.y + vt) * multiplier - vt * t
				);
			}

			void main() {
				float life = u_time / ${duration.toFixed(2)};

				v_life = life + a_data;

				vec2 position = projectileMotion2d(u_velocity, cometVt, u_time);
				float pointSizeScale = life < 0.75 ? 1.0 : map(life, 0.75, 1.0, 1.0, 0.0);

				gl_Position = u_projectionViewMatrix * vec4(position + u_origin.xy, u_origin.z, 1);
				gl_PointSize = ${HEADING_POINT_SIZE} * u_pxPerDegFov / gl_Position.z * pointSizeScale;
			}
		`,
		frag: `
			precision highp float;

			uniform vec3 u_color;
			uniform sampler2D u_tex;
			varying float v_life;

			void main() {
				if (v_life > 1.0) discard;
				vec4 texColor = texture2D(u_tex, gl_PointCoord);
				gl_FragColor = vec4(mix(u_color, vec3(1.0), texColor.r), texColor.a);
			}
		`,
		attributes: {
			a_data: headingData
		},
		uniforms: {
			u_origin: regl.prop('u_origin'),
			u_velocity: regl.prop('u_velocity'),
			u_color: regl.prop('u_color'),
			u_time: regl.prop('u_time'),
			u_tex: regl.prop('u_tex'),
			u_pxPerDegFov: regl.prop('u_pxPerDegFov'),
			u_projectionViewMatrix: regl.prop('u_projectionViewMatrix')
		},
		count: 2,
		primitive: 'points'
	});

	const drawSparks = regl({
		...commonReglConfig,
		vert: `
			precision highp float;

			attribute vec3 a_velocity;
			attribute vec3 a_data;

			uniform vec3 u_origin;
			uniform vec2 u_cometV0;
			uniform float u_time;
			uniform float u_pxPerDegFov;
			uniform mat4 u_projectionViewMatrix;

			varying float v_life;
			varying float v_alpha;

			// Gravitational acceleration (m/s^2)
			const float g = ${GRAVITY};
			// terminal velocity (m/s)
			const float cometVt = ${COMET_VT};
			const float sparkVt = ${SPARK_VT};

			float map(float value, float min1, float max1, float min2, float max2) {
				return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
			}

			vec2 projectileMotion2d(vec2 v0, float vt, float t) {
				float multiplier = 1.0 - exp(-g * t / vt);
				return vec2(
					vt * v0.x / g * multiplier,
					vt / g * (v0.y + vt) * multiplier - vt * t
				);
			}

			vec3 projectileMotion3d(vec3 v0, float vt, float t) {
				float multiplier = 1.0 - exp(-g * t / vt);
				return vec3(
					vt * v0.x / g * multiplier,
					vt / g * (v0.y + vt) * multiplier - vt * t,
					vt * v0.z / g * multiplier
				);
			}

			void main() {
				float startTime = a_data.x;
				float endTime = a_data.y;
				float headingVelocityFactor = a_data.z;

				float timeAlive = u_time - startTime;
				float life = map(u_time, startTime, endTime, 0.0, 1.0);

				// Calculate two heading positions separated by a small timestep to approximate the instantaneous velocity.
				vec3 sparkOrigin = vec3(projectileMotion2d(u_cometV0, cometVt, startTime), 0.0);
				vec3 sparkOrigin2 = vec3(projectileMotion2d(u_cometV0, cometVt, startTime + 0.001), 0.0);
				vec3 cometVelocity = (sparkOrigin2 - sparkOrigin) * 1000.0;

				vec3 position = projectileMotion3d(headingVelocityFactor*cometVelocity + a_velocity, sparkVt, timeAlive);
				gl_Position = u_projectionViewMatrix * vec4(position + sparkOrigin + u_origin, 1.0);

				float pointSize = ${glow._getQualityAdjustedSparkPointSizeForShader(BASE_SPARK_POINT_SIZE)} * u_pxPerDegFov / gl_Position.z;
				gl_PointSize = clamp(pointSize, 1.0, ${SPARK_POINT_SIZE_MAX});

				v_life = life;
				v_alpha = clamp(pointSize*pointSize, ${SPARK_POINT_ALPHA_MIN}, 1.0);
			}
		`,
		frag: `
			precision highp float;
			varying float v_life;
			varying float v_alpha;

			const vec3 startColor = vec3(1.0, 1.0, 1.0);
			const vec3 endColor = vec3(1.0, 0.5, 0.25);

			void main() {
				if (v_life < 0.0 || v_life > 1.0) discard;
				gl_FragColor = vec4(mix(startColor, endColor, v_life), v_alpha);
			}
		`,
		attributes: {
			a_velocity: sparkVelocities,
			a_data: sparkData
		},
		uniforms: {
			u_origin: regl.prop('u_origin'),
			u_cometV0: regl.prop('u_cometV0'),
			u_time: regl.prop('u_time'),
			u_pxPerDegFov: regl.prop('u_pxPerDegFov'),
			u_projectionViewMatrix: regl.prop('u_projectionViewMatrix')
		},
		count: sparkCount,
		primitive: 'points'
	});

	const fullDuration = duration + sparkBurnTime;
	const cometVelocity = [0, 0];

	function drawCometAtTime(currentTime, startTime, origin, angle, speed, color) {
		const rangeEnd = startTime + fullDuration;
		if (currentTime >= startTime && currentTime <= rangeEnd) {
			cometVelocity[0] = Math.sin(angle) * speed;
			cometVelocity[1] = Math.cos(angle) * speed;

			drawHeading({
				u_origin: origin,
				u_velocity: cometVelocity,
				u_color: color,
				u_time: currentTime - startTime,
				u_tex: starTexture,
				u_pxPerDegFov: camera.pxPerDegFov,
				u_projectionViewMatrix: camera.projectionViewMatrix
			});
			drawSparks({
				u_origin: origin,
				u_cometV0: cometVelocity,
				u_time: currentTime - startTime,
				u_pxPerDegFov: camera.pxPerDegFov,
				u_projectionViewMatrix: camera.projectionViewMatrix
			});
		}
	}

	return {
		duration: fullDuration,
		drawAtTime: drawCometAtTime
	};
}
