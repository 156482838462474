// On high-res ("retina") screens, we want the drawing to look crisp (especially important for small particles).
// However it's hard to justify rendering at the full native resolution of many modern displays, so we render semi-high-res.
// If the device pixel ratio is 2, we draw at 1.5. If the pixel ratio is 3, we draw at 2. This looks great and is cheaper.
// The code below does this, and also clamps the pixel ratio at a minimum of 1.0 (the reported `devicePixelRatio` can be
// less than 1.0 when a browser window is zoomed out). By clamping to 1.0, we support supersampling by zooming out.
// This is useful for performance testing. Generally though, any WebGL app should be run with the browser at 100% zoom.
export const PIXEL_RATIO = window.devicePixelRatio > 1 ? ((window.devicePixelRatio - 1) / 2 + 1) : 1;

// Gravitational acceleration applied to all particles (m/s^2)
export const GRAVITY = '9.8';

// Maximum rendered point size, in pixels, of any spark particle.
// Sparks can grow quite large when very close to the camera.
// This constant is meant to keep stray sparks from blotting out the whole screen.
export const SPARK_POINT_SIZE_MAX = (40 * PIXEL_RATIO).toFixed(1);
// Minimum alpha value of spark particles.
// This prevents unwanted color changes when extemely low-alpha particles accumulate at far distances.
export const SPARK_POINT_ALPHA_MIN = '0.014';
