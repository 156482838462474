/////////////////////////////////////////
// PLATFORM & FEATURE DETECTION MODULE //
/////////////////////////////////////////


// `userAgent` is all we need for most platform detection.
const userAgent = window.navigator.userAgent || '';

const iOS = /iPad|iPhone|iPod/.test(userAgent);
const android = /android/i.test(userAgent);
// Detects a mobile device (phone or tablet)
const mobile = iOS || android;


export default { mobile };
