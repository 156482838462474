export const PI_HALF = 0.5 * Math.PI;
export const TAU = 2 * Math.PI;
export const TO_DEG = 180 / Math.PI;
export const TO_RAD = Math.PI / 180;

// Generates a random number between min (inclusive) and max (exclusive)
export const random = (min, max) => Math.random() * (max - min) + min;

// Generates a random number between min (inclusive) and max (exclusive), weighted along an exponential curve defined by `exp`
export const randomExp = (min, max, exp) => Math.random()**exp * (max - min) + min;

// Generates a random integer between and possibly including min and max values
export const randomInt = (min, max) => ((Math.random() * (max - min + 1)) | 0) + min;

// Generates a random integer between and possibly including min and max values, weighted along an exponential curve defined by `exp`
export const randomIntExp = (min, max, exp) => ((Math.random()**exp * (max - min + 1)) | 0) + min;

// Returns a random element from an array, or simply the set of provided arguments when called
export const randomChoice = function randomChoice(choices) {
	if (arguments.length === 1 && Array.isArray(choices)) {
		return choices[(Math.random() * choices.length) | 0];
	}
	return arguments[(Math.random() * arguments.length) | 0];
};

// Clamps a number between min and max values (inclusive)
export const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

// Check if a number is within a range defined by min and max (inclusive)
export const inRange = (num, min, max) => num >= min && num <= max;

// Linearly interpolate between number 1 and number 2 by a specific amount.
// mix >= 0 && mix <= 1
export const interpolate = (a, b, mix) => (b - a) * mix + a;

// Returns relative position of a value between two other values.
export const getProgress = (num, start, end) => (num - start) / (end - start);

// Map a value within a defined range to another range.
export const map = (num, min1, max1, min2, max2) => {
	return min2 + (num - min1) * (max2 - min2) / (max1 - min1);
};
