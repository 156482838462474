import commonReglConfig from './commonReglConfig';
import { GRAVITY, SPARK_POINT_SIZE_MAX, SPARK_POINT_ALPHA_MIN } from './constants';
import { random, randomExp, randomChoice } from './customMath';



////////////
// CONFIG //
////////////

const SPARK_FORCE_MIN = 4; // m/s
const SPARK_FORCE_MAX = 16; // m/s
const SPARK_HZ = 18000; // sparks per second
const SPARK_CYCLES = 16;
const SPARK_BURN_TIME = 0.6; // seconds
const SPARK_BURN_VARIANCE = 0.8; // ratio; 0 = not random, 1 = fully random from 0 to SPARK_BURN_TIME
const SPARK_VT_MIN = 1.5; // m/s
const SPARK_VT_MAX = 3; // m/s
const BASE_SPARK_POINT_SIZE = 0.44;
const SPARK_DURATION = SPARK_BURN_TIME * SPARK_CYCLES;

const STAR_FORCE_MIN = 10; // m/s
const STAR_FORCE_MAX = 16; // m/s
const STAR_HZ = 100; // sparks per second
const STAR_CYCLES = 12;
const STAR_BURN_TIME = 0.8; // seconds
const STAR_BURN_VARIANCE = 0.2; // ratio; 0 = not random, 1 = fully random from 0 to STAR_BURN_TIME
const STAR_VT = '4.0'; // m/s
const STAR_POINT_SIZE = '20.0';
const STAR_DURATION = STAR_BURN_TIME * STAR_CYCLES;




const computeParticleCount = (hz, burnTime, cycles) => hz * burnTime * cycles;

const createFountainParticles = (hz, burnTime, burnVariance, cycles, factory) => {
	// Control how verticle the fountain spray is.
	const y = 3;

	const count = computeParticleCount(hz, burnTime, cycles);
	const gapTime = 1 / hz;
	const burnVarianceLow = 1 - burnVariance;

	for (let i=0; i<count; i++) {
		const angle = Math.random() * Math.PI * 2;
		const radius = Math.sqrt(Math.random());

		const x = Math.cos(angle) * radius;
		const z = Math.sin(angle) * radius;
		const startTime = i * gapTime;
		const endTime = startTime + randomExp(burnVarianceLow, 1, 2.5) * burnTime;

		// Normalize (x, y, z) when passing to factory.
		const mag = Math.hypot(x, y, z);
		factory(
			i,
			x/mag,
			y/mag,
			z/mag,
			startTime,
			endTime
		);
	}

};

export const makeFountainProgram = (glow, colors) => {
	const { regl, camera, starTexture } = glow;

	////////////////
	// SPARK DATA //
	////////////////
	const finalSparkHz = glow._getQualityAdjustedSparkCount(SPARK_HZ);
	const sparkCount = computeParticleCount(finalSparkHz, SPARK_BURN_TIME, SPARK_CYCLES);
	const sparkVelocities = new Float32Array(3 * sparkCount);
	const sparkData = new Float32Array(3 * sparkCount);

	createFountainParticles(finalSparkHz, SPARK_BURN_TIME, SPARK_BURN_VARIANCE, SPARK_CYCLES, (i, x, y, z, startTime, endTime) => {
		const velocityOffset = i * 3;
		const dataOffset = i * 3;

		const speed = random(SPARK_FORCE_MIN, SPARK_FORCE_MAX);
		const sparkVt = random(SPARK_VT_MIN, SPARK_VT_MAX);

		sparkVelocities[velocityOffset] = x * speed;
		sparkVelocities[velocityOffset+1] = y * speed;
		sparkVelocities[velocityOffset+2] = z * speed;

		sparkData[dataOffset] = startTime;
		sparkData[dataOffset+1] = endTime;
		sparkData[dataOffset+2] = sparkVt;
	});

	///////////////
	// STAR DATA //
	///////////////

	const starCount = computeParticleCount(STAR_HZ, STAR_BURN_TIME, STAR_CYCLES);
	const starVelocities = new Float32Array(3 * starCount);
	const starColors = new Float32Array(3 * starCount);
	const starData = new Float32Array(2 * starCount);

	createFountainParticles(STAR_HZ, STAR_BURN_TIME, STAR_BURN_VARIANCE, STAR_CYCLES, (i, x, y, z, startTime, endTime) => {
		const velocityOffset = i * 3;
		const dataOffset = i * 2;

		const speed = random(STAR_FORCE_MIN, STAR_FORCE_MAX);
		starVelocities[velocityOffset] = x * speed;
		starVelocities[velocityOffset+1] = y * speed;
		starVelocities[velocityOffset+2] = z * speed;

		const color = randomChoice(colors);
		starColors[velocityOffset] = color[0];
		starColors[velocityOffset+1] = color[1];
		starColors[velocityOffset+2] = color[2];

		starData[dataOffset] = startTime;
		starData[dataOffset+1] = endTime;
	});

	const drawSparks = regl({
		...commonReglConfig,
		vert: `
			precision highp float;

			attribute vec3 a_velocity;
			attribute vec3 a_data;

			uniform vec3 u_origin;
			uniform float u_angle;
			uniform float u_time;
			uniform float u_pxPerDegFov;
			uniform mat4 u_projectionViewMatrix;

			varying float v_life;
			varying float v_alpha;

			// Gravitational acceleration (m/s^2)
			const float g = ${GRAVITY};

			float map(float value, float min1, float max1, float min2, float max2) {
				return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
			}

			vec3 rotateZ(vec3 v, float angle) {
				return vec3(
					v.x * cos(angle) - v.y * sin(angle),
					v.x * sin(angle) + v.y * cos(angle),
					v.z
				);
			}

			vec3 projectileMotion3d(vec3 v0, float vt, float t) {
				float multiplier = 1.0 - exp(-g * t / vt);
				return vec3(
					vt * v0.x / g * multiplier,
					vt / g * (v0.y + vt) * multiplier - vt * t,
					vt * v0.z / g * multiplier
				);
			}

			void main() {
				float startTime = a_data.x;
				float endTime = a_data.y;
				float sparkVt = a_data.z;

				float timeAlive = u_time - startTime;
				float life = map(u_time, startTime, endTime, 0.0, 1.0);

				vec3 position = projectileMotion3d(rotateZ(a_velocity, u_angle), sparkVt, timeAlive);
				gl_Position = u_projectionViewMatrix * vec4(position + u_origin, 1.0);

				float pointSize = ${glow._getQualityAdjustedSparkPointSizeForShader(BASE_SPARK_POINT_SIZE)} * u_pxPerDegFov / gl_Position.z;
				gl_PointSize = clamp(pointSize, 1.0, ${SPARK_POINT_SIZE_MAX});

				v_life = life;
				v_alpha = clamp(pointSize*pointSize, ${SPARK_POINT_ALPHA_MIN}, 1.0);
			}
		`,
		frag: `
			precision highp float;
			varying float v_life;
			varying float v_alpha;

			const vec3 startColor = vec3(1.0, 0.5, 0.2);
			const vec3 endColor = vec3(0.6, 0.2, 0.0);

			void main() {
				if (v_life < 0.0 || v_life > 1.0) discard;
				gl_FragColor = vec4(mix(startColor, endColor, v_life), v_alpha);
			}
		`,
		attributes: {
			a_velocity: sparkVelocities,
			a_data: sparkData
		},
		uniforms: {
			u_origin: regl.prop('u_origin'),
			u_angle: regl.prop('u_angle'),
			u_time: regl.prop('u_time'),
			u_pxPerDegFov: regl.prop('u_pxPerDegFov'),
			u_projectionViewMatrix: regl.prop('u_projectionViewMatrix')
		},
		count: sparkCount,
		primitive: 'points'
	});


	const drawStars = regl({
		...commonReglConfig,
		vert: `
			precision highp float;

			attribute vec3 a_velocity;
			attribute vec3 a_color;
			attribute vec2 a_data;

			uniform vec3 u_origin;
			uniform float u_angle;
			uniform float u_time;
			uniform float u_pxPerDegFov;
			uniform mat4 u_projectionViewMatrix;

			varying float v_life;
			varying vec3 v_color;

			// Gravitational acceleration (m/s^2)
			const float g = ${GRAVITY};
			// terminal velocity (m/s)
			const float starVt = ${STAR_VT};

			float map(float value, float min1, float max1, float min2, float max2) {
				return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
			}

			vec3 rotateZ(vec3 v, float angle) {
				return vec3(
					v.x * cos(angle) - v.y * sin(angle),
					v.x * sin(angle) + v.y * cos(angle),
					v.z
				);
			}

			vec3 projectileMotion3d(vec3 v0, float vt, float t) {
				float multiplier = 1.0 - exp(-g * t / vt);
				return vec3(
					vt * v0.x / g * multiplier,
					vt / g * (v0.y + vt) * multiplier - vt * t,
					vt * v0.z / g * multiplier
				);
			}

			void main() {
				float startTime = a_data.x;
				float endTime = a_data.y;

				float timeAlive = u_time - startTime;
				float life = map(u_time, startTime, endTime, 0.0, 1.0);

				v_life = life;
				v_color = a_color;

				vec3 position = projectileMotion3d(rotateZ(a_velocity, u_angle), starVt, timeAlive);
				float pointSizeScale = life < 0.6 ? 1.0 : map(life, 0.6, 1.0, 1.0, 0.0);
				// Limit fragment shader calls when particle isn't alive, by setting point size to zero.
				if (life < 0.0) pointSizeScale = 0.0;

				gl_Position = u_projectionViewMatrix * vec4(position + u_origin, 1.0);
				gl_PointSize = ${STAR_POINT_SIZE} * u_pxPerDegFov / gl_Position.z * pointSizeScale;
			}
		`,
		frag: `
			precision highp float;

			uniform sampler2D u_tex;
			varying float v_life;
			varying vec3 v_color;

			void main() {
				if (v_life < 0.0 || v_life > 1.0) discard;
				vec4 texColor = texture2D(u_tex, gl_PointCoord);
				gl_FragColor = vec4(mix(v_color, vec3(1.0), texColor.r), texColor.a);
			}
		`,
		attributes: {
			a_velocity: starVelocities,
			a_color: starColors,
			a_data: starData
		},
		uniforms: {
			u_origin: regl.prop('u_origin'),
			u_angle: regl.prop('u_angle'),
			u_time: regl.prop('u_time'),
			u_tex: regl.prop('u_tex'),
			u_pxPerDegFov: regl.prop('u_pxPerDegFov'),
			u_projectionViewMatrix: regl.prop('u_projectionViewMatrix')
		},
		count: starCount,
		primitive: 'points'
	});

	function drawFountainAtTime(currentTime, startTime, duration, origin, angle) {
		const rangeEnd = startTime + duration;
		if (currentTime >= startTime && currentTime <= rangeEnd) {
			drawSparks({
				u_origin: origin,
				u_angle: -angle,
				u_time: (currentTime - startTime) % SPARK_DURATION,
				u_pxPerDegFov: camera.pxPerDegFov,
				u_projectionViewMatrix: camera.projectionViewMatrix
			});
			drawStars({
				u_origin: origin,
				u_angle: -angle,
				u_time: (currentTime - startTime) % STAR_DURATION,
				u_tex: starTexture,
				u_pxPerDegFov: camera.pxPerDegFov,
				u_projectionViewMatrix: camera.projectionViewMatrix
			});
		}
	}

	return {
		duration: 0,
		drawAtTime: drawFountainAtTime
	};
};
