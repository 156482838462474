import mat4 from 'gl-mat4';

import { PIXEL_RATIO } from './constants';
import { TO_RAD, TO_DEG, interpolate } from './customMath';
import { invariant } from './helpers';


const INITIAL_CAMERA = 'audience';
const CAMERA_ZOOM = 1;

const CAMERAS = {
	// Audience camera: great for everything
	audience: {
		// vertical field of view (degrees)
		fovY: 58,
		// position (meters)
		y: 2,
		z: 62,
		// rotation (degrees)
		rotationX: -17,
		orbitRotation: 0,
		// center point camera orbits around, in world space
		orbitPosition: [0, 0, -30]
	},
	// Ground camera: good for fountains
	ground: {
		fovY: 28,
		y: 2,
		z: 63,
		rotationX: -1,
		orbitRotation: 0,
		orbitPosition: [0, 0, 50]
	},
	// Shells-only camera: great for viewing shells
	shells: {
		fovY: 28,
		y: 0,
		z: 250,
		rotationX: -16,
		orbitRotation: 0,
		orbitPosition: [0, 0, -182]
	},
	// Far camera: overview of everything, at the cost of making things appear small.
	far: {
		fovY: 36,
		y: 75,
		z: 340,
		rotationX: -2,
		orbitRotation: 0,
		orbitPosition: [0, 0, -182]
	},
	// Sky cameras: overview of everything, looking down at an angle
	skyL: {
		fovY: 36,
		y: 500,
		z: 380,
		rotationX: 36,
		orbitRotation: 50,
		orbitPosition: [0, 0, -182]
	},
	skyR: {
		fovY: 36,
		y: 500,
		z: 380,
		rotationX: 36,
		orbitRotation: -50,
		orbitPosition: [0, 0, -182]
	}
};



// Abstract representation of a camera.
export default class Camera {
	constructor(glow) {
		this.glow = glow;
		// Set up in updateCameraMatrices();
		// The camera view the user is currently seeing.
		this.activeCamera = INITIAL_CAMERA;
		this.cameraFovY = 0;
		this.cameraOrbitPosition = null;
		this.cameraOrbitPositionInverse = [];
		// Camera z offset (meters)
		this.zOffset = 0;
		// Camera x/y offset (meters)
		this.panX = 0;
		this.panY = 0;
		// Camera orbit rotation (radians)
		this.orbitRotation = CAMERAS[this.activeCamera].orbitRotation * TO_RAD;
		// Set each frame by `computeCameraTransform()`
		this.pxPerDegFov = 0;
		// 3D transform matrices.
		this.projectionMatrix = [];
		this.viewMatrix = [];
		this.orbitMatrix = [];
		this.projectionViewMatrix = [];

		this.updateCameraMatrices();
	}

	setActiveCamera(cameraId) {
		invariant(CAMERAS.hasOwnProperty(cameraId), `Unrecognized camera "${cameraId}"`);
		this.activeCamera = cameraId;
		this.setOrbitRotation(CAMERAS[cameraId].orbitRotation * TO_RAD);
		this.updateCameraMatrices();
		this.glow.queueForceDraw();
	}

	setZOffset(num) {
		this.zOffset = num;
		this.updateCameraMatrices();
		this.glow.queueForceDraw();
	}

	setPan(x, y) {
		this.panX = x;
		this.panY = y;
		this.updateCameraMatrices();
		this.glow.queueForceDraw();
	}

	setOrbitRotation(angle) {
		this.orbitRotation = angle;
		this.glow.queueForceDraw();
	}

	setPxPerDegFov(fovY) {
		const fovDeg = fovY * TO_DEG;
		this.pxPerDegFov = this.glow.getHeight() * PIXEL_RATIO / fovDeg;
	}

	updateCameraMatrices() {
		const {
			activeCamera,
			cameraOrbitPositionInverse,
			zOffset,
			panX,
			panY,
			viewMatrix
		} = this;

		const camera = CAMERAS[activeCamera];

		this.cameraFovY = camera.fovY * TO_RAD;
		const cameraRotationX = camera.rotationX * TO_RAD;

		const cameraOrbitPosition = camera.orbitPosition;
		this.cameraOrbitPosition = cameraOrbitPosition;
		cameraOrbitPositionInverse[0] = -cameraOrbitPosition[0];
		cameraOrbitPositionInverse[1] = -cameraOrbitPosition[1];
		cameraOrbitPositionInverse[2] = -cameraOrbitPosition[2];

		mat4.fromTranslation(viewMatrix, [0, 0, zOffset]);
		mat4.rotateX(viewMatrix, viewMatrix, cameraRotationX);
		mat4.scale(viewMatrix, viewMatrix, [CAMERA_ZOOM, CAMERA_ZOOM, 1]);
		mat4.translate(viewMatrix, viewMatrix, [-panX, -camera.y - panY, -camera.z]);
	}

	computeCameraTransform() {
		const {
			glow,
			cameraFovY,
			cameraOrbitPosition,
			cameraOrbitPositionInverse,
			orbitRotation,
			projectionMatrix,
			viewMatrix,
			orbitMatrix,
			projectionViewMatrix
		} = this;

		const aspectRatio = glow.getWidth() / glow.getHeight();
		let finalFovY = cameraFovY;

		// On narrow screens, widen FOV (zoom out)
		if (aspectRatio < 1) {
			finalFovY = interpolate(cameraFovY / aspectRatio, cameraFovY, 0.5);
		}

		this.setPxPerDegFov(finalFovY);

		// Create matrix to convert model data into WebGL unit space.
		mat4.perspective(projectionMatrix, finalFovY, aspectRatio, 1, 2000);
		mat4.multiply(projectionViewMatrix, projectionMatrix, viewMatrix);

		// Apply camera orbit
		mat4.fromTranslation(orbitMatrix, cameraOrbitPosition);
		mat4.rotateY(orbitMatrix, orbitMatrix, orbitRotation);
		mat4.translate(orbitMatrix, orbitMatrix, cameraOrbitPositionInverse);
		mat4.multiply(projectionViewMatrix, projectionViewMatrix, orbitMatrix);
	}
}
