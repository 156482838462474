import { interpolate } from './customMath';

// Brightness at which we render white core.
const WHITE_CORE_THRESH = 0.48;
const WHITE_CORE_MULT = 1.0 / (1.0 - WHITE_CORE_THRESH);
const ALPHA_MULT = 1.65;
// Inner falloff parameters
const INNER_FALLOFF_POWER = 3.2;
const INNER_DIST_SCALE = 3.75;
// Outer falloff parameters
const OUTER_FALLOFF_POWER = 2;
const OUTER_FALLOFF_INTENSITY = 0.1;


/**
 * Generate a Regl texture representing a star glow.
 *
 * @param  {Regl} regl     - A Regl instance
 * @param  {int}  texSize  - Generated texture resolution
 *
 * @return {ReglTexture}
 */
export default function makeStarTexture(regl, texSize) {
	const starTextureData = new Uint8Array(texSize * texSize * 2);
	let i = 0;
	const consoleLabel = `Generate Star Texture (${texSize}×${texSize})`;
	console.time(consoleLabel);
	for (let y=0; y<texSize; y++) {
		const uv_y = y / (texSize - 1) - 0.5; // -0.5 to +0.5
		for (let x=0; x<texSize; x++) {
			const uv_x = x / (texSize - 1) - 0.5;  // -0.5 to +0.5
			const clampedDistance = Math.min(1.0, Math.sqrt(uv_x * uv_x + uv_y * uv_y) * 2); // 0 to 1, from center out.
			const brightness1 = Math.pow(Math.max(0.0, 1.0 - clampedDistance * INNER_DIST_SCALE), INNER_FALLOFF_POWER);
			const brightness2 = Math.pow(1.0 - clampedDistance, OUTER_FALLOFF_POWER) * OUTER_FALLOFF_INTENSITY;
			const brightness = Math.min(1.0, brightness1 + brightness2);
			const whiteValue1 = Math.max(0.0, brightness - WHITE_CORE_THRESH) * WHITE_CORE_MULT;
			const whiteValue2 = Math.pow(whiteValue1, 0.36);
			starTextureData[i] = interpolate(whiteValue1, whiteValue2, whiteValue1) * 255 | 0;
			const alphaDither = Math.random();
			starTextureData[i+1] = Math.min(255, brightness * ALPHA_MULT * 255 + alphaDither) | 0;
			i += 2;
		}
	}
	console.timeEnd(consoleLabel);

	const starTexture = regl.texture({
		width: texSize,
		height: texSize,
		format: 'luminance alpha',
		data: starTextureData,
		mag: 'linear',
		min: 'linear'
	});

	return starTexture;
}
