import { Color, EffectType } from './enums';
import { TO_RAD } from './customMath';
import { invariant } from './helpers';
import { expandShellData } from './effectHelpers';
import { makeShellProgram } from './shellPrograms';
import { makeFountainProgram } from './fountainPrograms';
import { makeCometProgram } from './cometPrograms';


// Helpers
function colorFromName(colorName) {
	const fixedName = colorName.substring(0, 1).toUpperCase() + colorName.substring(1).toLowerCase();
	return Color[fixedName];
}


export let showDuration = 0; // set in `generatePrograms`
export let showData = [];
export let showPrograms = [];

// Helper for `generatePrograms`, returns a Promise that resolves on the next event loop tick.
// This allows yielding back to the browser to handle events and repaint the screen, before running more code.
function getNextTickPromise() {
	return new Promise(resolve => setTimeout(resolve, 0));
}

async function generatePrograms(glow, handleProgressUpdate) {
	console.groupCollapsed('Initialization Timings');
	console.time('Full Initialization');

	showDuration = 0;
	showPrograms.length = 0;

	// How this function works:
	// We want to map `showData` effects to draw programs. This can take some time for large shows.
	// To avoid locking up the main thread for an extended time, we split the work up into chunks.
	// Programs are generated until `minChunkTime` has passed, at which point we yield back to the browser.
	// This process repeats until all programs are generated. This chunking of work and yielding to the
	// browser is what allows `handleProgressUpdate` to be useful for showing progress in the application.

	const minChunkTime = 30; // milliseconds
	const effectCount = showData.length;
	let chunkStartTime = performance.now();

	for (let i=0; i<effectCount; i++) {
		const effect = showData[i];
		console.time(`Generate ${effect.type} data`);

		const position = [effect.position.x, effect.position.y, effect.position.z];
		const { data } = effect;
		let expandedData;
		let draw;
		let angleRad;
		let program;

		switch (effect.type) {
			case EffectType.Fountain:
				program = makeFountainProgram(glow, data.colors.map(colorFromName));
				angleRad = effect.angle * TO_RAD;
				draw = time => program.drawAtTime(time, effect.ignition_time, data.duration, position, angleRad);
				break;
			case EffectType.Comet:
				program = makeCometProgram(glow, data.duration, data.sparkHz, data.sparkBurnTime);
				angleRad = effect.angle * TO_RAD;
				draw = time => program.drawAtTime(time, effect.ignition_time, position, angleRad, data.speed, colorFromName(data.color));
				break;
			case EffectType.Shell:
				expandedData = expandShellData(data);
				position[1] = position[1] || data.caliber * 21.336; // 70 feet high per inch caliber
				position[2] = position[2] || data.caliber * -21.336; // 70 feet away per inch caliber
				program = makeShellProgram(glow, {
					...expandedData,
					colors: expandedData.colors && expandedData.colors.map(colorFromName),
					pistil: expandedData.pistil && {
						...expandedData.pistil,
						colors: expandedData.pistil.colors && expandedData.pistil.colors.map(colorFromName)
					}
				});
				draw = time => program.drawAtTime(time, effect.ignition_time, position);
				break;
			default:
				throw new Error('switch must be exhaustive');
		}

		console.timeEnd(`Generate ${effect.type} data`);

		const effectEndTime = effect.ignition_time + program.duration;
		if (effectEndTime > showDuration) {
			showDuration = effectEndTime;
		}

		showPrograms.push(draw);

		// Once we cross the `minChunkTime` threshold, we want to yield back to the browser.
		// Before we yield, we need to notify user-code about the progress, and we queue a frame to be drawn
		// which allows effects to be rendered as they are generated.
		// AFTER the yield we reset the `chunkStartTime`, so the browser CPU time doesn't count towards the next chunk.
		if (performance.now() - chunkStartTime >= minChunkTime) {
			handleProgressUpdate && handleProgressUpdate(i / (effectCount - 1));
			glow.queueForceDraw();
			await getNextTickPromise();
			chunkStartTime = performance.now();
		}
	}

	console.timeEnd('Full Initialization');
	console.groupEnd('Initialization Timings');
}


export function updateShowData(glow, data, handleProgressUpdate) {
	console.time('Validation');
	if (__DEV__) {
		const validColors = ['', ...Object.keys(Color).map(color => color.toLowerCase())];
		const validEffectTypes = ['shell'];

		// Validation utils
		const lazyInvariant = (condition, messageFactory) => {
			if (!condition) {
				invariant(false, messageFactory());
			}
		};
		const formatValue = value => {
			if (typeof value === 'string') {
				return `"${value}"`;
			}
			else if (Array.isArray(value)) {
				return 'Array';
			}
			return value;
		};
		const formatPath = (path) => {
			let result = 'effect';
			for (const seg of path) {
				if (typeof seg === 'number') {
					result += `[${seg}]`;
				} else {
					result += `.${seg}`;
				}
			}
			return result;
		};
		const lookupPath = (effect, path) => {
			let value = effect;
			for (let i=0; i<path.length; i++) {
				const seg = path[i];
				lazyInvariant(
					value.hasOwnProperty(seg),
					() => `Effect ID "${effect.id}" is missing the property "${formatPath(path.slice(0, i + 1))}"`
				);
				value = value[seg];
			}
			return value;
		};
		const isNumber = (effect, path) => {
			const value = lookupPath(effect, path);
			lazyInvariant(
				typeof value === 'number',
				() => `Effect ID "${effect.id}": ${formatPath(path)} must be a number (offending value: ${formatValue(value)})`
			);
		};
		const isString = (effect, path) => {
			const value = lookupPath(effect, path);
			lazyInvariant(
				typeof value === 'string',
				() => `Effect ID "${effect.id}": ${formatPath(path)} must be a string (offending value: ${formatValue(value)})`
			);
		};
		const isArray = (effect, path) => {
			const value = lookupPath(effect, path);
			lazyInvariant(
				Array.isArray(value),
				() => `Effect ID "${effect.id}": ${formatPath(path)} must be an array (offending value: ${formatValue(value)})`
			);
		};
		// Lowercase check for one of known string values.
		const isOneOf = (effect, path, validValues) => {
			isString(effect, path);
			const value = lookupPath(effect, path);
			lazyInvariant(
				validValues.includes(value.toLowerCase()),
				() => `Effect ID "${effect.id}": ${formatPath(path)} must be one of: ${validValues.map(JSON.stringify).join(', ')} (offending value: ${formatValue(value)})`
			);
		};

		const validateBreak = (effect, propertyName) => {
			isArray(effect, ['data', propertyName, 'stars']);
			isOneOf(effect, ['data', propertyName, 'stars', 0, 'color'], validColors);
			isNumber(effect, ['data', propertyName, 'stars', 0, 'burn_duration']);
			isNumber(effect, ['data', propertyName, 'stars', 0, 'glitter_brightness']);
			isNumber(effect, ['data', propertyName, 'stars', 0, 'glitter_density']);
			isNumber(effect, ['data', propertyName, 'stars', 0, 'glitter_duration']);
			isNumber(effect, ['data', propertyName, 'star_density']);
			isNumber(effect, ['data', propertyName, 'force']);
		};

		for (const effect of data) {
			invariant(typeof effect === 'object', 'All effects must be objects');
			invariant(effect.hasOwnProperty('id'), 'All effects must have a ".id" property');
			invariant(typeof effect.id === 'string', `All effect IDs must be strings (offending value: ${effect.id})`);
			isOneOf(effect, ['type'], validEffectTypes);
			isNumber(effect, ['position', 'x']);
			isNumber(effect, ['ignition_time']);
			validateBreak(effect, 'shell_break');
			if (effect.data.pistil) {
				validateBreak(effect, 'pistil');
			}
		}
	}
	console.timeEnd('Validation');

	showData = data;
	return generatePrograms(glow, handleProgressUpdate);
}

export function getCssColorsFromEffect(effect) {
	try {
		const { data } = effect;
		let expandedData;
		let colors;

		switch (effect.type) {
			case EffectType.Fountain:
				colors = data.colors.map(colorFromName);
				break;
			case EffectType.Comet:
				colors = [colorFromName(data.color)];
				break;
			case EffectType.Shell:
				expandedData = expandShellData(data);
				colors = expandedData.colors ? expandedData.colors.map(colorFromName) : [];
				break;
			default:
				colors = [];
				break;
		}

		return colors.map(c => `rgb(${c[0]*255|0},${c[1]*255|0},${c[2]*255|0})`);
	} catch (e) {
		console.error(e);
		return [];
	}
}
