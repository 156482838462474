// Helper functions for working with effect data structures.
import vec3 from 'gl-vec3';

import { map } from './customMath';
import { mapFromScale } from './helpers';


const starCountByCaliber = [
	[1.75, 60],
	[2.4,  80],
	[3,   100],
	[4,   120],
	[6,   200],
	[8,   225],
	[10,  250]
];

const starBurnTimeByCaliber = [
	[1,    0.50],
	[1.75, 0.90],
	[2.4,  1.25],
	[3,    1.50],
	[4,    1.70],
	[6,    2.40],
	[8,    2.80],
	[10,   3.25],
	[12,   3.75]
];

function getStarCountForShell(shellData) {
	let baseStarCount;

	if (shellData.caliber <= 1.75) {
		baseStarCount = 60;
	} else {
		baseStarCount = Math.ceil(mapFromScale(starCountByCaliber, shellData.caliber));
	}

	return Math.ceil(baseStarCount * shellData.shell_break.star_density);
}


function getColorStringFromScale(colors, brightness) {
	const color = brightness < 0.5
		? vec3.lerp([], colors[0], colors[1], brightness*2)
		: vec3.lerp([], colors[1], colors[2], (brightness-0.5)*2);
	return `${color[0].toFixed(3)}, ${color[1].toFixed(3)}, ${color[2].toFixed(3)}`;
}

const sparkColorScaleBegin = [
	[0.0, 0.0, 0.0],
	[1.0, 0.5, 0.2],
	[1.0, 1.0, 1.0]
];

const sparkColorScaleEnd = [
	[0.0, 0.0, 0.0],
	[0.6, 0.2, 0.0],
	[1.0, 0.5, 0.25]
];


export function expandShellData(shellData) {
	// private computed properties
	const _star = shellData.shell_break.stars[0];
	const _hasColor = !!_star.color;

	// public computed properties
	const starCount = getStarCountForShell(shellData);
	const breakSpeed = map(shellData.caliber, 2, 12, 26, 61) * shellData.shell_break.force;
	const terminalVelocity = map(shellData.caliber, 2, 12, 14, 16.5);
	const burnTime = mapFromScale(starBurnTimeByCaliber, shellData.caliber) * _star.burn_duration;
	const burnTimeVariance = map(burnTime, 1, 10, 0.15, 0.075);
	const colors = shellData.shell_break.stars.map(star => star.color).filter(Boolean);
	let spark;
	if (_star.glitter_density > 0 && _star.glitter_brightness > 0) {
		spark = {
			hz: map(shellData.caliber, 2, 12, 120, 220) * _star.glitter_density,
			burnTime: map(shellData.caliber, 2, 12, 0.6, 1.1) * _star.glitter_duration,
			speed: _hasColor
				? map(shellData.caliber, 2, 12, 2, 3.5)
				: map(shellData.caliber, 2, 12, 1.8, 2.3),
			color1: getColorStringFromScale(sparkColorScaleBegin, _star.glitter_brightness),
			color2: getColorStringFromScale(sparkColorScaleEnd, _star.glitter_brightness)
		};
	}

	let pistil;
	if (shellData.pistil) {
		const pistilStarDensityMultiplier = 0.8;
		const pisilStarBurnTimeMultiplier = 1.5;

		pistil = expandShellData({
			caliber: shellData.caliber * 0.375,
			shell_break: {
				...shellData.pistil,
				star_density: shellData.pistil.star_density * pistilStarDensityMultiplier,
				force: shellData.pistil.force * shellData.shell_break.force,
				stars: shellData.pistil.stars.map(star => {
					if (star.hasOwnProperty('burn_duration')) {
						return {
							...star,
							burn_duration: star.burn_duration * pisilStarBurnTimeMultiplier
						};
					}
					return star;
				})
			}
		});
	}

	return {
		starCount,
		breakSpeed,
		terminalVelocity,
		burnTime,
		burnTimeVariance,
		spark,
		colors: colors.length > 0 ? colors : undefined,
		pistil
	};
}
