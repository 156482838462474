// We want to represent colors as RGB arrays where each component is in the 0...1 range.
// The following helper function makes it easy to convert a hex code like '1e7fff' into
// the desired format of [0.118, 0.498, 1.000].
/*
function hex2rgbArray(hex) {
    const split = [hex.substring(0, 2), hex.substring(2, 4), hex.substring(4, 6)];
    return `[${split.map(s => (parseInt(s, 16) / 255).toFixed(3)).join(', ')}]`;
}
*/

export const Color = {
	Pink:  [1.000, 0.184, 0.832],
	Red:    [1.000, 0.000, 0.263],
	Orange:  [1.000, 0.322, 0.149],
	Gold:   [1.000, 0.500, 0.200],
	Yellow:  [1.000, 0.865, 0.098],
	Green:  [0.078, 0.988, 0.337],
	Aqua:  [0.039, 1.000, 0.671],
	Blue:   [0.118, 0.458, 1.000],
	Purple: [0.752, 0.039, 1.000],
	White:  [1.000, 1.000, 1.000]
};


export const EffectType = {
	Comet: 'comet',
	FlamePot: 'flame_pot',
	Fountain: 'fountain',
	Mine: 'mine',
	Salute: 'salute',
	Shell: 'shell',
	ShellOfShells: 'shell_of_shells',
	StobePot: 'stobe_pot'
};


export const StarType = {
	Standard: 'standard',
	Crackle: 'crackle',
	Crossette: 'crossette',
	FallingLeaves: 'falling_leaves',
	FlyingFish: 'flying_fish',
	Lace: 'lace',
	NishikiKamuro: 'nishiki_kamuro',
	Strobe: 'strobe',
	Tourbillion: 'tourbillion'
};


export const ShellBreakType = {
	Standard: 'standard',
	Ghost: 'ghost',
	Horsetail: 'horsetail',
	Ring: 'ring'
};
