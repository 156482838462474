export default {
	depth: { enable: false },
	blend: {
		enable: true,
		func: {
			src: 'src alpha',
			dst: 'one'
		}
	}
};
