import createREGL from 'regl';

import { PIXEL_RATIO } from './constants';
import { invariant } from './helpers';
import detect from './detect';
import makeStarTexture from './makeStarTexture';
import makeDebugGridProgram from './makeDebugGridProgram';
import Camera from './Camera';
import {
	showPrograms,
	updateShowData,
	showDuration,
	showData,
	getCssColorsFromEffect
} from './show';


let glowInstanceCount = 0;

export default class Glow {
	constructor(container) {
		glowInstanceCount++;
		invariant(glowInstanceCount <= 1, 'Only one Glow instance may be instantiated.');

		let containerElement;
		if (typeof container === 'string') {
			containerElement = document.querySelector(container);
			invariant(containerElement, `"${container}" is not a valid selector.`);
		} else if (container instanceof Element) {
			invariant(!container.hasChildNodes(), 'The container node passed to "new Glow()" must be empty.');
			containerElement = container;
		} else {
			invariant(false, '"new Glow()" must be called with either a selector string or a DOM element.');
		}

		// Whenever a redraw is needed after any interaction, this flag can be set with `queueForceDraw()`.
		// `needsForceDraw` is read and cleared by the draw loop. It forces a draw even if playback is paused.
		// This means `queueForceDraw()` can be called multiple times without any performance overhead.
		this._needsForceDraw = false;
		this.currentTime = 0;
		this.sparkQuality = detect.mobile ? 1 : 2;


		this.regl = createREGL({
			pixelRatio: PIXEL_RATIO,
			container: containerElement
		});
		// console.log(JSON.stringify(regl.limits, null, 2));

		this._canvas = containerElement.querySelector('canvas');
		this.starTexture = makeStarTexture(this.regl, 256);
		this.camera = new Camera(this);

		// Monitor changes to element size and ensure we redraw canvas.
		// Regl automatically updates canvas size and frame buffers.
		const resizeObserver = new ResizeObserver(() => this.queueForceDraw());
		resizeObserver.observe(containerElement);

		// Expose helpers
		this.getCssColorsFromEffect = getCssColorsFromEffect;

		this._startFrameLoop();
	}



	/////////////
	// Setters //
	/////////////

	setCurrentTime(time) {
		this.currentTime = time;
	}

	setSparkQuality(quality) {
		this.sparkQuality = quality;
	}



	///////////////////////////////
	// Computed property getters //
	///////////////////////////////

	getWidth() {
		return this._canvas.offsetWidth;
	}

	getHeight() {
		return this._canvas.offsetHeight;
	}

	getShowDuration() {
		return showDuration;
	}

	getShowData() {
		return showData;
	}

	_getQualityAdjustedSparkCount(sparkCount) {
		return Math.ceil(sparkCount * this.sparkQuality);
	}

	// Scale spark size inversely to spark quality to maintain the apparent brightness of trails. This also means scaling the width/height
	// along a square root curve, so that the _area_ of the particle scales linearly.
	// Returns a string so it's guaranteed safe to concat into shader source code as a float value.
	_getQualityAdjustedSparkPointSizeForShader(sparkSize) {
		return (sparkSize * Math.sqrt(1 / this.sparkQuality)).toFixed(4);
	}



	//////////////////
	// Core Methods //
	//////////////////

	queueForceDraw() {
		this._needsForceDraw = true;
	}

	updateShowData(data, handleProgressUpdate) {
		invariant(Array.isArray(data), `"data" must be an array. Received "${data}"`);
		if (__DEV__) {
			invariant(
				!handleProgressUpdate || typeof handleProgressUpdate === 'function',
				`"handleProgressUpdate" must be a function. Received "${handleProgressUpdate}"`
			);
		}
		return updateShowData(this, data, handleProgressUpdate);
	}

	_startFrameLoop() {
		// Config
		const FORCE_DRAW_EVERY_FRAME = false; // Useful for perf testing
		const CLEAR_COLOR = { color: [0, 0, 0, 1] };

		// const drawGrid = makeDebugGridProgram(this.regl, 8, 30);

		let compareTime = -1;
		// Using `regl.frame()` for the benefits of auto-polling features like viewport size.
		// This means we don't redraw immediately when `currentTime` updates, but on the next available frame tick.
		this.regl.frame(() => {
			const {
				regl,
				camera,
				currentTime,
				_needsForceDraw
			} = this;

			if (showPrograms.length === 0) {
				return;
			}
			// Only draw when the current time has changed or a draw has been deliberately queued (typically due to interaction),
			// unless `FORCE_DRAW_EVERY_FRAME` is `true`.
			if (!FORCE_DRAW_EVERY_FRAME && currentTime === compareTime && !_needsForceDraw) {
				return;
			}
			compareTime = currentTime;
			this._needsForceDraw = false;


			// Camera calculations
			camera.computeCameraTransform();


			///////////
			// DRAW! //
			///////////

			regl.clear(CLEAR_COLOR);

			for (const program of showPrograms) {
				program(currentTime);
			}

			// drawGrid({
			// 	u_distance: -182,
			// 	u_color: [0, 0.6, 1],
			// 	u_projectionViewMatrix: camera.projectionViewMatrix
			// });
		});
	}
}

Glow.meta = {
	version: __VERSION__,
	optimized: !__DEV__
};
