import commonReglConfig from './commonReglConfig';
import { GRAVITY, SPARK_POINT_SIZE_MAX, SPARK_POINT_ALPHA_MIN } from './constants';
import { createBurst } from './helpers';
import { random, randomChoice, interpolate } from './customMath';



const STAR_DATA_VEC_SIZE = 4;
const STAR_COLOR_VEC_SIZE = 3;
const SPARK_VELOCITY_VEC_SIZE = 3;
const SPARK_DATA_VEC_SIZE = 2;

const BASE_SPARK_POINT_SIZE = 10;
const STAR_POINT_SIZE = '780.0';


const starDataReturn = {};
function makeStarData(count, speed, burnTime, burnTimeVariance, colors) {
	const hasColors = !!colors;
	const starData = new Float32Array(count * STAR_DATA_VEC_SIZE);
	let starColors = undefined;
	if (hasColors) {
		starColors = new Float32Array(count * STAR_COLOR_VEC_SIZE);
	}

	createBurst(count, (i, x, y, z) => {
		const dataOffset = i * STAR_DATA_VEC_SIZE;
		const burnTimeMultiplier = 1 - burnTimeVariance + Math.random() * burnTimeVariance;

		starData[dataOffset] = x * speed;
		starData[dataOffset+1] = y * speed + 2;
		starData[dataOffset+2] = z * speed;
		starData[dataOffset+3] = burnTime * burnTimeMultiplier;

		if (hasColors) {
			const colorOffset = i * STAR_COLOR_VEC_SIZE;
			const color = randomChoice(colors);
			starColors[colorOffset] = color[0];
			starColors[colorOffset+1] = color[1];
			starColors[colorOffset+2] = color[2];
		}
	});

	starDataReturn.starData = starData;
	starDataReturn.starColorData = starColors;
	return starDataReturn;
}

const sparkTrailDataReturn = {};
// Use a custom curve to create few sparks at the very start of star's life, while they're all
// clustered in the center, then quickly increase spark output early in star's life, and gradually
// reduce spark output as it dies.
const sparkSpawnCurve = p => {
	const v1 = p ** 0.68;
	const v2 = p ** 1.65;
	return interpolate(v1, v2, p ** 0.15);
};
function makeSparkTrailData(sourceStarData, sparkCountPerStar, sparkBurnTime, sparkSpeed) {
	const starCount = sourceStarData.length / STAR_DATA_VEC_SIZE;
	const sparkCount = sparkCountPerStar * starCount;

	const sparkStarData = new Float32Array(sparkCount * STAR_DATA_VEC_SIZE);
	const sparkVelocities = new Float32Array(sparkCount * SPARK_VELOCITY_VEC_SIZE);
	const sparkData = new Float32Array(sparkCount * SPARK_DATA_VEC_SIZE);
	for (let i=0; i<starCount; i++) {
		const sourceDataOffset = i * STAR_DATA_VEC_SIZE;
		const velocityX = sourceStarData[sourceDataOffset];
		const velocityY = sourceStarData[sourceDataOffset+1];
		const velocityZ = sourceStarData[sourceDataOffset+2];
		const starEndTime = sourceStarData[sourceDataOffset+3];

		for (let ii=0; ii<sparkCountPerStar; ii++) {
			// array offsets
			const sparkIndex = i * sparkCountPerStar + ii;
			const starDataOffset = sparkIndex * STAR_DATA_VEC_SIZE;
			const velocityOffset = sparkIndex * SPARK_VELOCITY_VEC_SIZE;
			const dataOffset = sparkIndex * SPARK_DATA_VEC_SIZE;
			// duplicate star data for each spark
			sparkStarData[starDataOffset] = velocityX;
			sparkStarData[starDataOffset+1] = velocityY;
			sparkStarData[starDataOffset+2] = velocityZ;
			sparkStarData[starDataOffset+3] = starEndTime;
			// generate spark data
			// ---------------------
			const startTime = sparkSpawnCurve((ii + 1) / sparkCountPerStar) * starEndTime;
			const burnTimeMultiplier = (Math.random() * 0.75 + 0.25) ** 2;
			const endTime = startTime + burnTimeMultiplier * sparkBurnTime;
			// velocity
			sparkVelocities[velocityOffset] = random(-sparkSpeed, sparkSpeed);
			sparkVelocities[velocityOffset+1] = random(-sparkSpeed, sparkSpeed);
			sparkVelocities[velocityOffset+2] = random(-sparkSpeed, sparkSpeed);
			// spark start/end time
			sparkData[dataOffset] = startTime;
			sparkData[dataOffset+1] = endTime;
		}
	}

	sparkTrailDataReturn.sparkCount = sparkCount;
	sparkTrailDataReturn.sparkStarData = sparkStarData;
	sparkTrailDataReturn.sparkVelocities = sparkVelocities;
	sparkTrailDataReturn.sparkData = sparkData;
	return sparkTrailDataReturn;
}


function makeStarProgram(glow, starDataIn, starCount, breakSpeed, terminalVelocity, burnTime, burnTimeVariance, colors) {
	const { regl } = glow;
	const { starData, starColorData } = starDataIn || makeStarData(starCount, breakSpeed, burnTime, burnTimeVariance, colors);

	const starDataBuffer = regl.buffer({
		data: starData,
		type: 'float',
		usage: 'dynamic'
	});

	const starColorBuffer = regl.buffer({
		data: starColorData,
		type: 'float',
		usage: 'dynamic'
	});

	const drawStars = regl({
		...commonReglConfig,
		vert: `
			precision highp float;

			attribute vec4 a_data;
			attribute vec3 a_color;

			uniform vec3 u_origin;
			uniform float u_time;
			uniform float u_pxPerDegFov;
			uniform mat4 u_projectionViewMatrix;

			varying vec3 v_color;
			varying float v_life;

			// Gravitational acceleration (m/s^2)
			const float g = ${GRAVITY};
			// Terminal velocity of particles (m/s)
			const float vt = ${terminalVelocity.toFixed(2)};

			float map(float value, float min1, float max1, float min2, float max2) {
				return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
			}

			vec3 projectileMotion3d(vec3 v0, float vt, float t) {
				float multiplier = 1.0 - exp(-g * t / vt);
				return vec3(
					vt * v0.x / g * multiplier,
					vt / g * (v0.y + vt) * multiplier - vt * t,
					vt * v0.z / g * multiplier
				);
			}

			void main() {

				vec3 v0 = a_data.xyz; // initial velocity (m/s)
				float endTime = a_data.w;

				float life = u_time / endTime;
				float pointSizeScale = 1.0;
				// Grow particle over first 0.3 seconds, and shrink over last 10% of lifetime.
				if (u_time < 0.3) pointSizeScale = u_time / 0.3;
				if (life > 0.9) pointSizeScale = map(life, 0.9, 1.0, 1.0, 0.0);
				vec3 position = projectileMotion3d(v0, vt, u_time);

				gl_Position = u_projectionViewMatrix * vec4(position + u_origin, 1.0);
				gl_PointSize = ${STAR_POINT_SIZE} * u_pxPerDegFov / gl_Position.z * pointSizeScale;

				v_color = a_color;
				v_life = life;
			}
		`,
		frag: `
			precision highp float;
			uniform sampler2D u_tex;
			varying vec3 v_color;
			varying float v_life;

			void main() {
				if (v_life > 1.0) discard;
				vec4 texColor = texture2D(u_tex, gl_PointCoord);
				gl_FragColor = vec4(mix(v_color, vec3(1.0), texColor.r), texColor.a);
			}
		`,
		attributes: {
			a_data: starDataBuffer,
			a_color: starColorBuffer
		},
		uniforms: {
			u_origin: regl.prop('u_origin'),
			u_time: regl.prop('u_time'),
			u_pxPerDegFov: regl.prop('u_pxPerDegFov'),
			u_projectionViewMatrix: regl.prop('u_projectionViewMatrix'),
			u_tex: regl.prop('u_tex')
		},
		count: starCount,
		primitive: 'points'
	});

	return {
		duration: burnTime,
		draw: drawStars
	};
}

function makeSparkTrailProgram(glow, starDataIn, starCount, breakSpeed, starTerminalVelocity, starBurnTime, starBurnTimeVariance, sparkHz, sparkBurnTime, sparkSpeed, color1, color2) {
	const { regl } = glow;

	//////////////////
	// DATA BUFFERS //
	//////////////////

	// generate unique star data
	const { starData } = starDataIn || makeStarData(starCount, breakSpeed, starBurnTime, starBurnTimeVariance, undefined);

	const sparkCountPerStar = glow._getQualityAdjustedSparkCount(starBurnTime * sparkHz);
	const { sparkCount, sparkStarData, sparkVelocities, sparkData } = makeSparkTrailData(starData, sparkCountPerStar, sparkBurnTime, sparkSpeed);

	const starDataBuffer = regl.buffer({
		data: sparkStarData,
		type: 'float',
		usage: 'dynamic'
	});
	const sparkVelocitiesBuffer = regl.buffer({
		data: sparkVelocities,
		type: 'float',
		usage: 'dynamic'
	});
	const sparkDataBuffer = regl.buffer({
		data: sparkData,
		type: 'float',
		usage: 'dynamic'
	});

	const drawSparks = regl({
		...commonReglConfig,
		vert: `
			precision highp float;

			attribute vec4 a_starData;
			attribute vec3 a_sparkVelocity;
			attribute vec2 a_sparkData;

			uniform vec3 u_origin;
			uniform float u_time;
			uniform float u_pxPerDegFov;
			uniform mat4 u_projectionViewMatrix;

			varying float v_life;
			varying float v_alpha;

			// Gravitational acceleration (m/s^2)
			const float g = ${GRAVITY};
			// Terminal velocity of particles (m/s)
			const float starVt = ${starTerminalVelocity.toFixed(2)};
			const float sparkVt = 1.0;

			float map(float value, float min1, float max1, float min2, float max2) {
				return min2 + (value - min1) * (max2 - min2) / (max1 - min1);
			}

			// v0 = initial velocity
			// vt = terminal velocity
			// t = time
			vec3 projectileMotion3d(vec3 v0, float vt, float t) {
				float multiplier = 1.0 - exp(-g * t / vt);
				return vec3(
					vt * v0.x / g * multiplier,
					vt / g * (v0.y + vt) * multiplier - vt * t,
					vt * v0.z / g * multiplier
				);
			}

			void main() {
				vec3 starV0 = a_starData.xyz; // initial velocity (m/s)
				float starEndTime = a_starData.w;
				float startTime = a_sparkData.x;
				float endTime = a_sparkData.y;

				float timeAlive = u_time - startTime;
				float life = map(u_time, startTime, endTime, 0.0, 1.0);

				vec3 sparkOrigin = projectileMotion3d(starV0, starVt, startTime);
				vec3 sparkPosition = projectileMotion3d(a_sparkVelocity, sparkVt, timeAlive);

				gl_Position = u_projectionViewMatrix * vec4(sparkOrigin + sparkPosition + u_origin, 1.0);

				float pointSize = ${glow._getQualityAdjustedSparkPointSizeForShader(BASE_SPARK_POINT_SIZE)} * u_pxPerDegFov / gl_Position.z;
				gl_PointSize = clamp(pointSize, 1.0, ${SPARK_POINT_SIZE_MAX});

				v_life = life;
				v_alpha = clamp(pointSize*pointSize, ${SPARK_POINT_ALPHA_MIN}, 1.0);
			}
		`,
		frag: `
			precision highp float;
			varying float v_life;
			varying float v_alpha;

			const vec3 startColor = vec3(${color1});
			const vec3 endColor = vec3(${color2});

			void main() {
				if (v_life < 0.0 || v_life > 1.0) discard;
				gl_FragColor = vec4(mix(startColor, endColor, v_life), v_alpha);
			}
		`,
		attributes: {
			a_starData: starDataBuffer,
			a_sparkVelocity: sparkVelocitiesBuffer,
			a_sparkData: sparkDataBuffer
		},
		uniforms: {
			u_origin: regl.prop('u_origin'),
			u_time: regl.prop('u_time'),
			u_pxPerDegFov: regl.prop('u_pxPerDegFov'),
			u_projectionViewMatrix: regl.prop('u_projectionViewMatrix')
		},
		count: sparkCount,
		primitive: 'points'
	});

	return {
		duration: starBurnTime + sparkBurnTime,
		draw: drawSparks
	};
}


export function makeShellProgram(glow, { starCount, breakSpeed, terminalVelocity, burnTime, burnTimeVariance, spark, colors, pistil }) {
	const { camera, starTexture } = glow;

	// generate unique star data
	const starData = makeStarData(starCount, breakSpeed, burnTime, burnTimeVariance, colors);

	let starProgram = undefined;
	let sparkProgram = undefined;
	if (colors) starProgram = makeStarProgram(glow, starData, starCount, breakSpeed, terminalVelocity, burnTime, burnTimeVariance, colors);
	if (spark) sparkProgram = makeSparkTrailProgram(glow, starData, starCount, breakSpeed, terminalVelocity, burnTime, burnTimeVariance, spark.hz, spark.burnTime, spark.speed, spark.color1, spark.color2);

	const pistilProgram = pistil ? makeShellProgram(glow, pistil) : undefined;

	function drawShellAtTime(currentTime, startTime, origin) {
		if (starProgram) {
			const rangeEnd = startTime + starProgram.duration;
			if (currentTime >= startTime && currentTime <= rangeEnd) {
				starProgram.draw({
					u_origin: origin,
					u_time: currentTime - startTime,
					u_pxPerDegFov: camera.pxPerDegFov,
					u_projectionViewMatrix: camera.projectionViewMatrix,
					u_tex: starTexture
				});
			}
		}
		if (sparkProgram) {
			const rangeEnd = startTime + sparkProgram.duration;
			if (currentTime >= startTime && currentTime <= rangeEnd) {
				sparkProgram.draw({
					u_origin: origin,
					u_time: currentTime - startTime,
					u_pxPerDegFov: camera.pxPerDegFov,
					u_projectionViewMatrix: camera.projectionViewMatrix
				});
			}
		}

		if (pistilProgram) {
			pistilProgram.drawAtTime(currentTime, startTime, origin);
		}
	}

	const mainDuration = sparkProgram ? sparkProgram.duration : starProgram.duration;

	return {
		duration: pistilProgram ? Math.max(mainDuration, pistilProgram.duration) : mainDuration,
		drawAtTime: drawShellAtTime
	};
}
